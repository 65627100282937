@import 'config.scss';

.Hero {
  transform: translateZ(0);

  .clientsLogos {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    justify-items: center;

    padding-top: 32px;
    @include breakpoint($md) {
      display: flex;
      flex-wrap: wrap;
      padding-top: 56px;
      row-gap: 24px;
      column-gap: 56px;
    }
  }
}

.heroWrapper {
  z-index: 1;
  padding: 112px 0 32px 0;

  h1 {
    max-width: 960px;
    margin-bottom: 16px;
    text-align: left;
    font-size: 33px;
    line-height: 43px;
    text-align: center;
    @include breakpoint($md) {
      font-size: 53px;
      line-height: 71px;
      text-align: start;
    }
  }

  h2 {
    color: $grey-400;
    text-align: left;
    text-align: center;
    @include breakpoint($md) {
      text-align: start;
    }
  }

  .animations {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    justify-items: center;
    margin-top: 40px;
    @include breakpoint($md) {
      margin-top: 64px;
      grid-template-columns: 1.83fr 1fr;
    }

    .singleAnimation {
      figure {
        svg {
          border: 6px solid rgba(255, 255, 255, 0.4);
          border-radius: 4px;
        }
      }
    }
  }
  .oneAnimation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint($md) {
      height: 550px;
    }

    video {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  .oneVideo {
    width: 100%;
  }

  @include breakpoint($md) {
    padding: 136px 0 120px 0;
    h1 {
      text-align: center;
    }
    h2 {
      text-align: center;
    }
    .animations {
      grid-template-columns: var(--home-hero-animations-grid-cols, 1.83fr 1fr);
    }
  }
}

.arrowWrapper {
  display: none;
  position: absolute;
  bottom: 170px;

  @include breakpoint($md) {
    display: block;
  }
}

.background {
  display: flex;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #181826;
  .img {
    height: fit-content;
    width: 100%;
  }
}
